import * as Sentry from '@sentry/svelte';
import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';

if (import.meta.env.PROD) {
	FullStory.init({ orgId: 'o-1KXJAR-na1' });

	Sentry.init({
		dsn: 'https://ef99d556c2f1f0d566fba4e994cee62a@o4505716873428992.ingest.sentry.io/4505716876312576',
		tracesSampleRate: 1,
		integrations: [new SentryFullStory('gooddeedsai', { client: FullStory })]
	});
}

export const handleError = ({ error }) => {
	if (import.meta.env.PROD) {
		Sentry.captureException(error);
	}
	
	return {
		message: error instanceof Error && 'message' in error ? error.message : String(error)
	};
};
